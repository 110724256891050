import Swiper from 'swiper';
import Swal from 'sweetalert2';

const FullScreenDialog = Swal.mixin({
  grow: 'fullscreen',
  background: '#EEEEEE',
  showCloseButton: true,
  showConfirmButton: false,
  animation: false,
  customClass: {
    popup: 'animated fadeInUp',
    closeButton: 'close-button-class'
  },
});

var details = [
  {
    name: 'Martin B. Hartono',
    position: 'CEO Of GDP Venture'
  },
  {
    name: 'Danny Oei Wirianto',
    position: 'CMO Of GDP Venture'
  },
  {
    name: 'Kusumo Martanto',
    position: 'COO of GDP Venture'
  },
  {
    name: 'On Lee',
    position: 'CTO Of GDP Venture'
  },
  {
    name: 'Benny Sudrata',
    position: 'CFO Of GDP Venture'
  }
];

var leaders = new Swiper('.leaders', {
  slidesPerView: 3,
  slidesOffsetBefore: 30,
  slidesOffsetAfter: 30,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});

var generatedHTML = [];

for (var i = 0; i < 5; i++) {
  var elem = document.createElement("img");
  elem.src = "/images/leader/" + (i + 1) + ".png";
  var slide = document.createElement("div");
  slide.classList.add("swiper-slide");
  slide.appendChild(elem);

  var name_tag = document.createElement("H1")
  var name_node = document.createTextNode(details[i].name);
  name_tag.appendChild(name_node);
  slide.appendChild(name_tag);

  var desg_tag = document.createElement("H3")
  var desg_node = document.createTextNode(details[i].position);
  desg_tag.appendChild(desg_node);
  slide.appendChild(desg_tag);

  var overlay = document.createElement("div");
  overlay.classList.add("overlay-back");
  slide.appendChild(overlay);

  generatedHTML.push(slide);
}
console.log(generatedHTML);
leaders.addSlide(1, generatedHTML);

var html = [

  '<div class="leaderModalContainer">' +
  '<div class="leaderBox" data-aos="fade-right" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<div class="overlay-back-modal"></div>' +
  '<img src="/images/leader/1.png">' +
  '<img src="/images/left-icon-leader.png" class="left-arrow"/>' +
  '<img src="/images/right-icon-leader.png" class="right-arrow"/>' +
  '<div>' +
  '<div class="leaderName">Martin Hartono</div>' +
  '<div class="leaderTitle">CEO of GDP Venture</div>' +
  '</div>' +
  '</div>' +
  '<div class="leaderDetails"  data-aos="fade-left" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<p>Martin has a strong passion for gaming and technology, combined with a wealth of experience as Djarum Group’s Business Technology Director, led him in founding GDP Venture, a venture builder focusing on digital communities, media, commerce, and solution companies in 2010.</p></br></br>' +
  '<p>Martin loves teaching. Besides running GDP Venture as CEO, Martin spends his time mentoring local talents at the Founders Institute Jakarta. He believes, with the right mindset, process, infrastructure and determination, Indonesia can breed world-class technopreneurs, engineers, product developers, marketers and internet companies.</p></br></br>' +
  '<p>Martin is also an avid photographer, grappling enthusiast, basketball player, guitar slayer and doting father of three. He said, “Loving what you do is the best gift anyone can have.”</p></br>' +
  '</div>' +
  '</div>',

  '<div class="leaderModalContainer">' +
  '<div class="leaderBox" data-aos="fade-right" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<div class="overlay-back-modal"></div>' +
  '<img src="/images/leader/2.png">' +
  '<img src="/images/left-icon-leader.png" class="left-arrow"/>' +
  '<img src="/images/right-icon-leader.png" class="right-arrow"/>' +
  '<div>' +
  '<div class="leaderName">Danny Oei Wirianto</div>' +
  '<div class="leaderTitle">CMO of GDP Venture</div>' +
  '</div>' +
  '</div>' +
  '<div class="leaderDetails"  data-aos="fade-left" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<p>Danny is the Chief Marketing Officer (CMO) of GDP Venture and a serial entrepreneur focusing in digital and advertising marketing industries. He founded several companies such as Mindtalk.com, SemutApi Colony, MerahPutih Inc, Bolalob.com, Kincir.com and MediaXasia to name a few. He is an angel investor in Kaskus, Carousell, Eaze, High Times, 88Rising, and others. Danny is also a mentor in Founder Institute where he shares his experience on Vision & Validation.</p></br></br>' +
  '<p>During his professional year, Danny has won numerous awards and recognitions, such as Chief Marketing Officer Asia of the Year (2010), One of the Best Mentor in Asia by Founders Institute (2014), Top 100 Young Influencers in Indonesia by MarkplusInc., and Top 10 Young Business Person of The Year by JawaPos Group 2016.</p></br></br>' +
  '<p>Currently he is the Chief Digital of Indonesian Advertising Agencies Association or PPPI (Persatuan Perusahaan Periklanan Indonesia).</p></br>' +
  '</div>' +
  '</div>',

  '<div class="leaderModalContainer">' +
  '<div class="leaderBox" data-aos="fade-right" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<div class="overlay-back-modal"></div>' +
  '<img src="/images/leader/3.png">' +
  '<img src="/images/left-icon-leader.png" class="left-arrow"/>' +
  '<img src="/images/right-icon-leader.png" class="right-arrow"/>' +
  '<div>' +
  '<div class="leaderName">Kusumo Martanto</div>' +
  '<div class="leaderTitle">COO of GDP Venture</div>' +
  '</div>' +
  '</div>' +
  '<div class="leaderDetails"  data-aos="fade-left" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<p>Kusumo is the Chief Executive Officer (CEO) of Blibli.com, Indonesia’s leading e-commerce portal that offers the best online shopping experience and services.Prior to returning to Indonesia to help co-founding GDP and GDN (Blibli.com), Kusumo spent more than 15 years in the United States, working for Global Fortune Top 100 companies like Honeywell Aerospace, i2 Technologies and Intel. Besides leading Blibli.com, he holds a position as the Chief Operating Officer (COO) of GDP Venture, a holding company of Blibli.com.<br/></p>' +
  '<p>In early 2015, Kusumo was awarded the The Marketeer of the Year Jakarta 2015 for e-Commerce. He was also elected as the VP Commerce for ICSB (International Council for Small Business) Indonesia chapter. Besides being a technology enthusiast, he can be seen playing tennis, swimming and reading a few books in his spare time.</p>' +
  '</div>' +
  '</div>',

  '<div class="leaderModalContainer">' +
  '<div class="leaderBox" data-aos="fade-right" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<div class="overlay-back-modal"></div>' +
  '<img src="/images/leader/4.png">' +
  '<img src="/images/left-icon-leader.png" class="left-arrow"/>' +
  '<img src="/images/right-icon-leader.png" class="right-arrow"/>' +
  '<div>' +
  '<div class="leaderName">On Lee</div>' +
  '<div class="leaderTitle">CTO of GDP Venture</div>' +
  '</div>' +
  '</div>' +
  '<div class="leaderDetails"  data-aos="fade-left" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<p>On Lee is the Chief Technology Officer (CTO) at GDP Venture, GDP Labs and Kaskus. He has over 30 years of experience in internet, mobile, consumer and enterprise software development. He has built teams to start strategic new products in startup and large companies in the USA, Indonesia, China and India.<br/><br/></p>' +
  '<p>On Lee has held various management and technical positions as a co-founder, Chief Technology Officer (CTO), Executive Vice President of Engineering, and engineer in both startup and Fortune 500 companies. Currently, he is also trusted as Kaskus Chief Operating Officer (COO).</p>' +
  '</div>' +
  '</div>',

  '<div class="leaderModalContainer">' +
  '<div class="leaderBox" data-aos="fade-right" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<div class="overlay-back-modal"></div>' +
  '<img src="/images/leader/5.png">' +
  '<img src="/images/left-icon-leader.png" class="left-arrow"/>' +
  '<img src="/images/right-icon-leader.png" class="right-arrow"/>' +
  '<div>' +
  '<div class="leaderName">Benny Sudrata</div>' +
  '<div class="leaderTitle">CFO of GDP Venture</div>' +
  '</div>' +
  '</div>' +
  '<div class="leaderDetails"  data-aos="fade-left" data-aos-offset="100" data-aos-delay="200" data-aos-duration="500" data-aos-easing="ease-in-out-quad" >' +
  '<p>Benny firmly believes that digital is the future. That’s the reason why he joined GDP Venture, bringing his high expertise in finance.<br/><br/></p>' +
  '<p>After graduated from Universitas Gadjah Mada, Benny started his career as financial auditor at Dr. Moechtar Talib & Co. for 3 years until 1981. A year later, he joined Djarum group until 1996 as an Internal Auditor, Audit Manager, and Corporate Financial Controller.  In 1997, Benny took on a President Director role at Dinamika Usaha Jaya (now known as BCA Sekuritas). The year 2012 marked an important milestone for Benny when he decided to move into digital industry under GDP Venture.<br/><br/></p>' +
  '<p>Reading and small morning walk has become Benny’s daily routine. It calms his mind and makes him ready for any challenges. He said, “Do these simple activities, and you will be ready to ignite big achievements.”<br/><br/><p>' +
  '</div>' +
  '</div>',
]

document.getElementsByClassName("leaders-container")[0].getElementsByClassName("swiper1")[0].getElementsByClassName("swiper-slide")[0].onclick = function () {

  FullScreenDialog.fire({
    html: html[0]
  })
};

document.getElementsByClassName("leaders-container")[0].getElementsByClassName("swiper1")[0].getElementsByClassName("swiper-slide")[1].onclick = function () {

  FullScreenDialog.fire({
    html: html[1]
  })
};

document.getElementsByClassName("leaders-container")[0].getElementsByClassName("swiper1")[0].getElementsByClassName("swiper-slide")[2].onclick = function () {

  FullScreenDialog.fire({
    html: html[2]
  })
};

document.getElementsByClassName("leaders-container")[0].getElementsByClassName("swiper1")[0].getElementsByClassName("swiper-slide")[3].onclick = function () {

  FullScreenDialog.fire({
    html: html[3]
  })
};

document.getElementsByClassName("leaders-container")[0].getElementsByClassName("swiper1")[0].getElementsByClassName("swiper-slide")[4].onclick = function () {

  FullScreenDialog.fire({
    html: html[4]
  })
};

